var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-2"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/roles"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_role")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_role")) + " ")])], 1)]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6 pt-2"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("name")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "name",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "description"
    }
  }, [_vm._v(_vm._s(_vm.$t("description")) + " ")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.description,
      expression: "formData.description"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "description",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "description", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "border hover:border-green-500 mt-4 px-4 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "description"
    }
  }, [_vm._v(_vm._s(_vm.$t("permissions")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('div', {}, [_c('div', {
    staticClass: "w-full mt-2"
  }, [_c('table', {
    staticClass: "table w-full text-center"
  }, [_c('thead', {
    staticClass: "bg-gray-100 text-black"
  }, [_c('tr', [_c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("model")) + " ")]), _c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]), _c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("read")) + " ")]), _c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]), _c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")])])]), _c('tbody', _vm._l(_vm.modules, function (el, index) {
    return _c('tr', {
      key: index,
      class: index % 2 == 0 ? 'bg-gray-100' : ''
    }, [_c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-700 capitalize"
    }, [_vm._v(" " + _vm._s(el.model) + " ")])]), _c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-600 ml-3 text-semibold capitalize"
    }, [_c('label', {
      staticClass: "inline-flex text-sm items-center capitalize"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: el.access.create,
        expression: "el.access.create"
      }],
      staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(el.access.create) ? _vm._i(el.access.create, null) > -1 : el.access.create
      },
      on: {
        "change": function change($event) {
          var $$a = el.access.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(el.access, "create", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(el.access, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(el.access, "create", $$c);
          }
        }
      }
    })])])]), _c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-600 ml-2 text-semibold capitalize"
    }, [_c('label', {
      staticClass: "inline-flex text-sm items-center capitalize"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: el.access.read,
        expression: "el.access.read"
      }],
      staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(el.access.read) ? _vm._i(el.access.read, null) > -1 : el.access.read
      },
      on: {
        "change": function change($event) {
          var $$a = el.access.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(el.access, "read", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(el.access, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(el.access, "read", $$c);
          }
        }
      }
    })])])]), _c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-600 ml-3 text-semibold capitalize"
    }, [_c('label', {
      staticClass: "inline-flex text-sm items-center capitalize"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: el.access.update,
        expression: "el.access.update"
      }],
      staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(el.access.update) ? _vm._i(el.access.update, null) > -1 : el.access.update
      },
      on: {
        "change": function change($event) {
          var $$a = el.access.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(el.access, "update", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(el.access, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(el.access, "update", $$c);
          }
        }
      }
    })])])]), _c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-600 ml-3 text-semibold capitalize"
    }, [_c('label', {
      staticClass: "inline-flex text-sm items-center capitalize"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: el.access.delete,
        expression: "el.access.delete"
      }],
      staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(el.access.delete) ? _vm._i(el.access.delete, null) > -1 : el.access.delete
      },
      on: {
        "change": function change($event) {
          var $$a = el.access.delete,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(el.access, "delete", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(el.access, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(el.access, "delete", $$c);
          }
        }
      }
    })])])])]);
  }), 0)])])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }